$primary: #3b5ea6;
$secondary: #a9aabc;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
$light: #efefef;
$dark: #182145;

// $enable-gradients: true;
// $enable-shadows: true;
$enable-negative-margins: true;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
$font-family-base: "Montserrat", sans-serif;

@import "~bootstrap/scss/bootstrap.scss";
